tindie.ads = (function(t, $) {
	t.initAd = function(zoneId, containerId) {
		// set data
		var m3_r = Math.floor(Math.random() * 99999999999);
		var m3_u = '//ads.supplyframe.com/openads/adjs.php';
		var charset = (document.charset ? '&charset=' + document.charset : (document.characterSet ? '&charset=' + document.characterSet : ''));
		// generate url
		var url = m3_u + '?zoneid=' + zoneId;
		if (document.MAX_used != undefined && document.MAX_used != ',') url += '&exclude=' + document.MAX_used;
		if (charset) url += charset;
		url += '&loc=' + escape(window.location);
		if (document.referrer) url += '&referer=' + escape(document.referrer);
		if (document.context) url += '&context=' + escape(document.context);
		if (document.mmm_fo) url += '&mmm_fo=1';
		url += '&n=' + m3_r;
		// Build url params and make the ad call
		if ($('#ad-container-zone-' + containerId).length > 0) {
			postscribe('#ad-container-zone-' + containerId, '<script src="' + url + '"><\/script>', {
				error: function(e) {
					$('.product-card.sf-postscribe').remove();
				},
			});
		}
	}
	t.trackClick = function() {
		if (window.ga && ga.create) {
			ga('send', 'event', {
				eventCategory: 'Product Listing',
				eventAction: 'Clicked SF Ad',
				eventLabel: 'Q4 2018',
				transport: 'beacon'
			});
		}
		return true;
	}
	t.init = function() {}
	return t;
})(tindie.ads || {}, jQuery);