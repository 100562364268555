tindie.blog = (function(t, $) {
	var blogURL = "/fetch/blog/";
	var BlogPost = function(data) {
		var self = this;
		if (data) {
			ko.mapping.fromJS(data, {}, self);
		}
	}
	t.getBlogPosts = function(numPosts, parentArray, fields) {
		var url = blogURL + numPosts + '/';
		var data = {
			fields: fields
		};
		$.get(url, data).done(function(response) {
			if (response.success == true) {
				for (var i = 0; i < response.posts.length; i++) {
					var p = new BlogPost(response.posts[i]);
					parentArray.push(p);
				}
			}
		});
	};
	t.init = function() {}
	return t;
})(tindie.blog || {}, jQuery);