tindie.home = (function(t, $) {
	var products = tindie.productUtils,
		ads = tindie.ads,
		blog = tindie.blog;
	var sendImpressions = function() {
		if (window.ga && ga.create) {
			ga("send", "event", "Homepage", "Send Product Impressions", {
				nonInteraction: true,
				transport: "beacon"
			});
		}
	};
	var Home = function() {
		var self = this;
		tindie.app.base.call(self);
		ads.initAd(568, 1);
		self.newProducts = ko.observableArray();
		self.popularProducts = ko.observableArray();
		self.blogPosts = ko.observableArray();
		self.showBlogPosts = ko.computed(function() {
			return self.blogPosts().length > 0;
		});
		self.trackClick = function() {
			return ads.trackClick();
		};
		self.createNewProduct = function() {
			return products.createProductCard("Homepage Newest Products", self.newProducts);
		};
		self.createPopularProduct = function() {
			return products.createProductCard("Homepage Popular Products", self.popularProducts);
		};
	};
	t.init = function() {
		var vm = new Home();
		ko.applyBindings(vm, document.body);
		blog.getBlogPosts(3, vm.blogPosts, 'title,link,thumb');
		window.addEventListener("beforeunload", sendImpressions, false);
	};
	return t;
})(tindie.home || {}, jQuery);